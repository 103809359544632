.container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
  padding: 20px 20px 60px;
}

.text-center {
  text-align: center;
}

.emoji {
  font-size: 22px;
}

.spacer {
  height: 10px;
  width: 100%;
}
